@font-face {
  font-family: 'Manrope';
  src: url('/fonts/Manrope-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('/fonts/Manrope-Bold.ttf');
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}

:root {
  --reach-dialog: 1;
  // Shared
  --color-brand-primary: #2dd6a0;
  --color-brand-primary-hover: #6ce2bc;
  --color-brand-secondary: #bf88ff;
  --color-brand-secondary-hover: #d2abff;
  --color-failure: #ed4b9e;
  --color-failure-hover: #f281bb;
  --color-success: #2dd6a0;
  --color-error: #ff0000;
  --color-warning: #ffb865;
  --color-increase: #2dd6a0;
  --color-decrease: #ed4b9e;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray: #888888;
}

.light {
  // Text
  --color-text-default: #000000;
  --color-text-secondary: #c3c3c3;
  --color-text-tertiary: #ffffff;
  --color-text-disabled: #888888;
  // Background
  --color-bg-default: #ffffff; // 1
  --color-bg-secondary: #ededf2; // 2
  --color-bg-tertiary: #fbfbfb; // 3
  --color-bg-disabled: #ededf2;
  --color-bg-input: #e8f4f5;
  --color-bg-overlay: rgba(0, 0, 0, 0.55);
  --color-bg-scaffold: #ededf2;
}

.dark {
  // Text
  --color-text-default: #ffffff;
  --color-text-secondary: #888888;
  --color-text-tertiary: #000000;
  --color-text-disabled: #888888;
  // Background
  --color-bg-default: #25263b; // 1
  --color-bg-secondary: #141425; // 2
  --color-bg-tertiary: #191b2e; // 3
  --color-bg-disabled: #141425;
  --color-bg-input: #141425;
  --color-bg-overlay: rgba(0, 0, 0, 0.8);
  --color-bg-scaffold: #000000;
}

html,
body {
  margin: 0;
  padding: 0;
}

.-z-1 {
  z-index: -1;
}

.loader {
  border-color: var(--color-bg-secondary);
  border-top-color: var(--color-brand-primary);
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.progress-bar {
  position: relative;
  height: 6.5px;
  background: linear-gradient(90deg, #99f9da 27.86%, #2dd6a0 51.61%);
  border-radius: 8px;
}

.progress-bar .separation-bar {
  height: 6.5px;
  background: linear-gradient(90deg, #ff0000 3.21%, #ffd3d4 99.99%);
  border-radius: 8px;
}

.progress-bar.disabled {
  background: var(--color-bg-disabled);
}

.progress-bar.disabled .separation-bar {
  background: var(--color-bg-disabled);
}

.progress-bar .angle {
  position: absolute;
  top: -9.5px;
  margin-left: -7px;
  transition: left 600ms ease;
}

.alert-shadow {
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

.badge-dot::before {
  content: '';
  height: 8px;
  width: 8px;
  background: currentColor;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.more-shadow {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
}

.custom-button-active-effect {
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
}

.custom-button-active-effect:active:not(.remove-active-effect) {
  transform: translateY(1px);
  box-shadow: none;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.remove-active-effect {
  box-shadow: none;
}

.bm-burger-icon {
  transform-origin: 0.8px;
}

.bm-menu-wrap {
  margin-top: 1rem;
}

.bm-burger-button {
  z-index: 30 !important;
}

.bm-burger-button button,
.bm-burger-button button:focus {
  outline: none;
}

.fill-default {
  color: var(--color-bg-default);
}

.fill-current {
  fill: currentColor;
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
}

.dark .react-loading-skeleton {
  --base-color: #3a3b51;
  --highlight-color: #25263b;
}

.dark-mode-toggle {
  filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.2));
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
}

.dark .dark-mode-toggle {
  background-color: #3a3b51;
  transform: translateX(2rem);
}

.parrot-modal {
  [data-reach-dialog-content] {
    margin: auto;
    padding: 0;
    background: transparent;
    width: 480px;
  }
}

[data-reach-dialog-overlay] {
  background: var(--color-bg-overlay) !important;
  z-index: 100;
}

.css-1noh96p {
  margin-top: 40px !important;
}

.css-1m2tswi {
  top: 38px !important;
}

// tailwind
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
