@use "sass:math";
$glitch-intensity: 5;
$glitch-speed: 60;
$width: 156;
$height: 110;

@keyframes noise-anim {
  $steps: $glitch-speed;

  @for $i from 0 through $steps {
    #{percentage($i* math.div(1, $steps))} {
      clip: rect(random($width) + px, 9999px, random($height) + px, 0);
    }
  }
}

.glitch-effect {
  position: absolute;
  left: -5px;
  clip: rect(0, 9000px, 0, 0);
  animation: noise-anim 3s infinite linear alternate-reverse;
  display: none;

  &.show {
    display: block;
  }
}
